// src/store/auth/action-types.ts
export const LOGIN_USER_VIEW = 'LOGIN_USER_VIEW';
export const LOGIN_USER_ACTION = 'LOGIN_USER_ACTION';
export const TRACK_EVENT = 'TRACK_EVENT';
export const TRACK_PAGE_VIEW = 'TRACK_PAGE_VIEW';
export const RESET_PASSWORD_VIEW = 'RESET_PASSWORD_VIEW';
export const RESET_PASSWORD_ACTION = 'RESET_PASSWORD_ACTION';
export const REGISTER_USER_VIEW = 'REGISTER_USER_VIEW';
export const REGISTER_USER_ACTION = 'REGISTER_USER_ACTION';

export const SETTINGS_VIEW_G = 'SETTINGS_VIEW_G';
export const EDIT_PROFILE_VIEW_G = 'EDIT_PROFILE_VIEW_G';
export const EDIT_PROFILE_ACTION_G = 'EDIT_PROFILE_ACTION_G';
export const GENERAL_NOTIFICATIONS_VIEW_G = 'GENERAL_NOTIFICATIONS_VIEW_G';
