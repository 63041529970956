export const SHOW_TOAST = 'SHOW_TOAST';
export const EXPAND_DRAWER = 'EXPAND_DRAWER';
export const EXPAND_DRAWER_TRIGGER = 'EXPAND_DRAWER_TRIGGER';
export const COLLAPSE_DRAWER = 'COLLAPSE_DRAWER';
export const COLLAPSE_DRAWER_TRIGGER = 'COLLAPSE_DRAWER_TRIGGER';
export const SHOW_LOADING = 'SHOW_LOADING';
export const SHOW_ACTUAL_SECTION = 'SHOW_ACTUAL_SECTION';
export const CHANGE_BACKGROUND = 'CHANGE_BACKGROUND';
export const HIDE_GRADIENT = 'HIDE_GRADIENT';

export const OPEN_PUBLISH_MODAL = 'OPEN_PUBLISH_MODAL';
export const OPEN_PUBLISH_MODAL_TRIGGER = 'OPEN_PUBLISH_MODAL_TRIGGER';
export const CLOSE_PUBLISH_MODAL = 'CLOSE_PUBLISH_MODAL';
export const CLOSE_PUBLISH_MODAL_TRIGGER = 'CLOSE_PUBLISH_MODAL_TRIGGER';

export const SET_SEPARATION = 'SET_SEPARATION';
